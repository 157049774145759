<!--
 * @Author: yangzhiqiang
 * @Date: 2024-02-20 18:12:18
 * @LastEditors: yangzhiqiang
 * @LastEditTime: 2024-02-22 13:56:03
-->
<template>
  <div class="recruit-box">
    <div class="head">
      <div class="head-title">进驻鸡窝</div>
    </div>
    <div class="box">
      <div class="box-title">招聘信息</div>
      <div class="box-msg">终于来啦，鸡哥等你很久啦</div>
      <div class="box-text">
        如果你激情爆棚，目标感超强，DDL虐你千百遍，你却待她如初恋；头可破、血可流，鸡哥任务不能丢，那么你一定是鸡窝中的战斗鸡！咱鸡强马壮钱包鼓，开放自由不添堵，鸡智的你还不快速速打包简历砸过来！
      </div>
      <div class="box-hr">联系HR</div>
      <div class="box-hr_msg">联系电话：0551-6555 8888 简历投递邮箱：hr@32ka.com</div>
      <div class="box-card">
        <el-row :gutter="$store.getters.getIsPhone ? 50 : 0">
          <el-col :sm="24" :md="12">
            <div class="cards" :style="{ height: $store.getters.getIsPhone ? '600px' : '100%' }">
              <div class="card-title">客服专员</div>
              <div class="card-msg">岗位职责：</div>
              <div class="card-text">
                1、负责客户使用产品的售前售后服务工作；
                <br />
                2、及时接听电话、有效回复玩家及运营商QQ问题并提供专业解答，及时将用户问题反馈给相关部门；
                <br />
                3、使用文明规范的服务用语；做好情绪客户的安抚工作；
                <br />
                4、分析用户需要，记录用户行为,为相关部门提供数据支撑；
                <br />
                5、定期归纳、总结服务工作；
              </div>
              <div class="card-msg">任职要求：</div>
              <div class="card-text">
                1、熟悉计算机基本操作，熟悉常用办公软件及办公设备；
                <br />
                2、打字速度快；
                <br />
                3、普通话标准，流利，口齿清楚,沟通表达能力较强，有较强的学习意识与能力；
                <br />
                4、工作认真、负责，对待本职工作热情、稳重；
                <br />
                5、有主动、积极的服务意识；
                <br />
                6、做事严谨,耐心细致，有一定的应变能力；
                <br />
                7、能接受夜班者优先考虑。
              </div>
            </div>
          </el-col>
          <el-col :sm="24" :md="12">
            <div class="cards" :style="{ height: $store.getters.getIsPhone ? '600px' : '100%' }">
              <div class="card-title">客服经理</div>
              <div class="card-msg">岗位职责：</div>
              <div class="card-text">
                1、负责公司客服团队的日常管理，提升客服工作专业程度；
                <br />
                2、负责汇总分析客服日常工作数据，总结业务出现的问题，并与相关部门协调解决；
                <br />
                3、优化客服工作流程，提升客服工作效率；
                <br />
                4、负责与大客户的沟通，提升合作质量；
              </div>
              <div class="card-msg">任职要求：</div>
              <div class="card-text">
                1、大专以上学历，客服及相关工作经验三年以上，互联网行业优先；
                <br />
                2、工作细致，责任心强，擅长数据分析者优先；
                <br />
                3、具备较强的沟通协调能力，有一定的团队管理能力，有二十人以上客服团队管理经验者优先。
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.recruit-box {
  background-color: #fafafa;
}
.head {
  width: 100%;
  height: 21vw;
  background: #ff6245 url(../assets/recruit/join_bg1.png) no-repeat center top;
  color: #fff;
  padding-top: 6vw;
  box-sizing: border-box;
  background-size: cover;
  .head-title {
    width: 100%;
    text-align: center;
    font-size: 4vw;
    color: #fff;
  }
}
.box {
  max-width: 1200px;
  margin: 0 auto;
  padding: 69px 0;
  .box-title {
    width: 100%;
    color: #ff6245;
    font-size: 34px;
  }
  .box-msg {
    width: 100%;
    color: #666;
    margin-top: 10px;
    font-size: 20px;
  }
  .box-text {
    width: 100%;
    padding: 20px 0;
    color: #666;
    font-size: 16px;
    line-height: 26px;
    border-bottom: 1px solid #eae9e9;
  }
  .box-hr {
    width: 100%;
    margin-top: 20px;
    font-size: 22px;
    color: #000;
  }
  .box-hr_msg {
    width: 100%;
    margin-top: 10px;
    font-size: 16px;
    color: #666;
  }
  .box-card {
    width: 100%;
    margin-top: 20px;
    .cards {
      border: 1px solid #eae9e9;
      border-radius: 10px;
      padding: 24px;
      transition: all 0.2s linear;
      -webkit-transition: all 0.2s linear;
      background-color: #fff;
      box-sizing: border-box;
      .card-title {
        width: 100%;
        font-size: 28px;
        color: #000;
      }
      .card-msg {
        width: 100%;
        font-size: 18px;
        color: #000;
        margin-top: 19px;
      }
      .card-text {
        width: 100%;
        margin-top: 10px;
        font-size: 16px;
        color: #545454;
        line-height: 27px;
      }
    }
    .cards:hover {
      box-shadow: 0 0 10px 0 #00000020;
    }
  }
}
</style>
